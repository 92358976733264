import { Header, SideMenu } from "@/components";
import { APP_HEADER_HEIGHT, appConfig } from "@/constants";
import { useAssetImport } from "@/context/AssetImportProvider";
import { type DeeplyNestedSx, Stack } from "@biom3/react";
import { useSpyScrollPercentage } from "@ui-kit/hooks/useSpyScrollPercentage";
import { usePathname } from "next/navigation";
import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

type TrackingEvent = {
  screen: string;
  userJourney: string;
  extras?: Record<string, unknown> | undefined;
};

type TrackingEventRef = {
  [K in keyof TrackingEvent]: TrackingEvent[K] | null;
};

type Props = PropsWithChildren & TrackingEvent;

import { usePassportProvider } from "@/context";
import { BiomeProvider } from "@/context/BiomeProvider";
import { useFlag } from "@/hooks/useFlags";
import { pageLegacyEvent, trackLegacyEvent } from "@analytics";
import { PromoBanner } from "@components/Takeover/PromoBanner";

const LAYOUT_SX: DeeplyNestedSx = {
  h: "100%",
  m: "0 auto",
  pos: "relative",
  bg: "base.color.translucent.inverse.800",
  overflow: "hidden",
  pt: ({ base }) => ({
    default: `calc(${APP_HEADER_HEIGHT} + ${base.spacing.x2})`,
    medium: `calc(${APP_HEADER_HEIGHT} + ${base.spacing.x4})`,
  }),
};

const LAYOUT_CONTENT_SX = {
  flex: 1,
  h: "100%",
};

const LAYOUT_CONTENT_INNER_SX = {
  flexGrow: 1,
  px: {
    default: "base.spacing.x2",
    medium: "base.spacing.x4",
  },
  overflow: "auto",
};

const RQ_PROMO_BANNER_DATA = {
  gameLink: "/balance",
  badgeContent: "New",
  heading: (
    <>
      The wait is over.
      <br />
      RavenQuest is live.
    </>
  ),
  description:
    "Everything you need to know about the biggest web3 MMORPG game launch.",
  buttonText: "Discover $QUEST",
  videoUrl: `${appConfig.ASSET_BASE_URL}/video/raven-quest-promo-banner-bg.webm`,
  analytics: {
    section: "RavenQuestPromoBanner",
    gameId: "f8dc910f-6fdb-4b16-8d3e-d2586b5d3b2b",
    gameName: "RavenQuest",
  },
};

export function Layout({ children, screen, extras, userJourney }: Props) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isBannerClosed, setIsBannerClosed] = useState(false);
  const { endImport } = useAssetImport();
  const path = usePathname();
  const { walletAddress } = usePassportProvider();
  const rqBannerEnabled = useFlag("playRqPromoBanner");

  const menuRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<TrackingEventRef>({
    screen: null,
    userJourney: null,
    extras: null,
  });

  const isMenuClicked = (event: MouseEvent) =>
    menuRef?.current?.contains(event.target as Node);
  const isHeaderClicked = (event: MouseEvent) =>
    headerRef?.current?.contains(event.target as Node);
  const isGamesOrQuestsPage =
    path === "/quests/" || path === "/games/" || path === "/";

  useEffect(() => {
    const ref = trackRef.current;
    const isNewPage = screen !== ref.screen || userJourney !== ref.userJourney;

    if (isNewPage) {
      pageLegacyEvent({ screen, userJourney, extras });
      trackRef.current = {
        screen,
        userJourney,
      };
    }
  }, [screen, userJourney, extras]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handleClickListener = (event: MouseEvent) => {
      if (!isMenuClicked(event) && !isHeaderClicked(event)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  }, []);

  const handleOnScrollThreshold = (percentage: string) => {
    trackLegacyEvent({
      screen: trackRef.current.screen ?? path ?? "Screen",
      userJourney: trackRef.current.userJourney ?? "Page",
      action: "Scrolled",
      extras: { path, percentage },
    });
  };
  const scrollSpyRef = useSpyScrollPercentage<HTMLDivElement>(
    handleOnScrollThreshold,
  );
  const handleMenuClick = useCallback(
    () => setMenuVisible(!menuVisible),
    [menuVisible],
  );
  const handleTitleClick = useCallback(() => setMenuVisible(false), []);
  const handleItemClick = useCallback(() => {
    setMenuVisible(false);
    endImport?.();
  }, [endImport]);
  const sideMenuSx = useMemo(
    () => ({
      translate: {
        default: menuVisible ? "0 0" : "-10% 0",
        medium: "0 0",
      },
      opacity: {
        default: menuVisible ? "1" : "0",
        medium: "1",
      },
      pointerEvents: {
        default: menuVisible ? "auto" : "none",
        medium: "auto",
      },
    }),
    [menuVisible],
  );

  const handleCloseBanner = useCallback(() => {
    setIsBannerClosed(true);
    if (walletAddress) {
      localStorage.setItem(`showPromoBanner-${walletAddress}`, "false");
    }
  }, [walletAddress]);

  const showPromoBanner = useMemo(() => {
    if (!walletAddress) return false;
    return localStorage.getItem(`showPromoBanner-${walletAddress}`) !== "false";
  }, [walletAddress]);

  return (
    <BiomeProvider>
      <Stack gap="0px" sx={LAYOUT_SX} testId="Layout">
        <Header
          domRef={headerRef}
          onMenuClick={handleMenuClick}
          onTitleClick={handleTitleClick}
        />
        <Stack
          direction={["column", "row"]}
          gap="0px"
          sx={LAYOUT_CONTENT_SX}
          testId="Layout__content"
        >
          <SideMenu
            domRef={menuRef}
            onItemClick={handleItemClick}
            sx={sideMenuSx}
          />

          <Stack
            direction="column"
            gap="0px"
            sx={LAYOUT_CONTENT_INNER_SX}
            id="layoutContentOuter"
          >
            {rqBannerEnabled &&
              isGamesOrQuestsPage &&
              showPromoBanner &&
              !isBannerClosed && (
                <PromoBanner
                  onClose={handleCloseBanner}
                  {...RQ_PROMO_BANNER_DATA}
                />
              )}
            <Stack
              domRef={scrollSpyRef}
              direction="row"
              gap="0px"
              testId="Layout__content__inner"
              id="rootScrollableContainer"
            >
              {children}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </BiomeProvider>
  );
}
