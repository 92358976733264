import {
  type DeeplyNestedSx,
  Divider,
  type IconSubcomponentDiscrimintatedUnion,
  MenuItem,
  Stack,
  isDualVariantIcon,
  useGetMotionProfile,
} from "@biom3/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { type FC, Fragment, type Ref, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  DISCOVER_PAGE,
  GAMES_PAGE,
  HOME_PAGE,
  SIDEBAR_WIDTH,
} from "@/constants";
import { usePassportProvider } from "@/context";
import { usePreferences } from "@/context/PreferencesProvider";
import { useGetWalletGems } from "@/hooks/api";
import type { HideActivationBadges, WalletGems } from "@/types";
import { trackLegacyEvent } from "@analytics";
import { useFlags } from "launchdarkly-react-client-sdk";
import dynamic from "next/dynamic";
import { merge } from "ts-deepmerge";
import { StatusDevPanel } from "../Status/StatusDevPanel";
import { FilterMenu } from "./FilterMenu";

type ContentProps = {
  walletGems: WalletGems | undefined;
};

type ActivationBadgeProps = {
  hideActivationBadges: HideActivationBadges;
  itemKey: string;
};

type MenuItemType = IconSubcomponentDiscrimintatedUnion & {
  visible: boolean;
  key: string;
  label: string;
  path?: string;
  content?: FC<ContentProps>;
  activationBadge?: FC<ActivationBadgeProps>;
  divider?: boolean;
  linkProps?: {
    isExternal?: boolean;
    isTrusted?: boolean;
  };
  onClick?: () => void;
};

const shouldDisplayBadge = (
  key: string,
  hideActivationBadges: HideActivationBadges,
) => hideActivationBadges[key as keyof HideActivationBadges] !== true;

const ActivationBadge: FC<ActivationBadgeProps> = (props) =>
  shouldDisplayBadge(props.itemKey, props.hideActivationBadges) ? (
    <MenuItem.Badge
      variant="guidance"
      isAnimated
      testId="menu-item__badge-dot"
    />
  ) : (
    <></>
  );

const BalanceBadge = dynamic(() => import("./BalanceBadge"));

const getMenuItems = ({
  isLoggedIn,
  discoverFeedFlag,
}: {
  isLoggedIn: boolean;
  discoverFeedFlag: boolean | undefined;
}): MenuItemType[] => [
  {
    visible: discoverFeedFlag === true,
    key: "discover",
    label: "discover",
    path: DISCOVER_PAGE,
    icon: "Flash",
    iconVariant: "bold",
  },
  {
    visible: true,
    key: "quest_gems",
    label: "daily_gems",
    path: HOME_PAGE,
    icon: "Shield",
    iconVariant: "bold",
  },
  {
    visible: true,
    key: "games",
    label: "games",
    path: GAMES_PAGE,
    icon: "ESports",
    iconVariant: "bold",
  },
  {
    visible: true,
    key: "rewards",
    label: "rewards",
    path: "/rewards",
    icon: "ImxRewards",
    iconVariant: "bold",
    content: ({ walletGems }) =>
      walletGems ? (
        <MenuItem.Badge
          variant="emphasis"
          badgeContent={`${walletGems.gems} Gems`}
          testId="menu-item__badge-gems"
        />
      ) : null,
    divider: true,
  },
  {
    visible: true,
    key: "balances",
    label: "balance",
    path: "/balance",
    icon: "Wallet",
    iconVariant: "bold",
    activationBadge: ActivationBadge,
    content: () => <BalanceBadge />,
  },
  {
    visible: true,
    key: "inventory",
    label: "inventory",
    path: "/inventory",
    icon: "TreasureChest",
    iconVariant: "bold",
    activationBadge: ActivationBadge,
  },
  {
    visible: isLoggedIn,
    key: "external_wallets",
    label: "external_wallets",
    path: "/external-wallets",
    icon: "WalletConnect",
    activationBadge: ActivationBadge,
  },
];

const MENU_ITEM_SX = {
  "&.selected": {
    backgroundColor:
      "var(--on-dark-base-color-translucent-emphasis-500, rgba(243, 243, 243, 0.20))",
  },
  ".innerContainer": {
    gap: "base.spacing.x3", // Spacing to allow Rewards + Gems badge to render 4-5 digit balances
  },
};

function isMenuItemSelected(routerPath: string, itemPath?: string) {
  if (!itemPath) return false;
  if (routerPath === itemPath) return true;
  return itemPath !== "/" && routerPath.includes(itemPath);
}

export function SideMenu({
  domRef,
  onItemClick,
  sx = {},
}: {
  domRef: Ref<HTMLDivElement>;
  onItemClick: () => void;
  sx?: DeeplyNestedSx;
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { discoverFeed: discoverFeedFlag, rewardsStatusDevPanelEnabled } =
    useFlags();
  const { walletAddress, isLoggedIn } = usePassportProvider();
  const { walletGems } = useGetWalletGems(walletAddress || null, false);
  const { data: preferencesData } = usePreferences();
  const isGamesPage = router.pathname === "/games";
  const menuItems = useMemo(
    () =>
      getMenuItems({
        isLoggedIn,
        discoverFeedFlag,
      }),
    [isLoggedIn, discoverFeedFlag],
  );

  const onItem = () => {
    onItemClick();
  };

  const onGemsTab = () => {
    trackLegacyEvent({
      userJourney: "Gems",
      screen: "PassportDashboard",
      control: "SideMenu",
      controlType: "Link",
      action: "Pressed",
    });
    onItemClick();
  };

  const motionProfileToUse = useGetMotionProfile();

  const mergedSx = useMemo(
    () =>
      merge(
        {
          w: { default: "100%", medium: "unset" },
          pos: { default: "absolute", medium: "relative" },
          background: { default: "base.color.neutral.900", medium: "unset" },
          zIndex: { default: "base.zLevel.tooltip", medium: "unset" },
          flexShrink: 0,
          h: "100%",
          transitionProperty: "translate, opacity",
          transitionDuration: `base.motion.normal.${motionProfileToUse}.cssDuration`,
          transitionTimingFunction: `base.motion.normal.${motionProfileToUse}.cssEase`,
          overflow: "auto",
        },
        sx,
      ),
    [sx, motionProfileToUse],
  );

  const farLeftSideMenuSx = useMemo(
    () => ({
      h: "100%",
      w: { default: "100%", medium: SIDEBAR_WIDTH },
      background: "base.color.translucent.standard.150",
      bradtr: {
        default: "base.borderRadius.x8",
        large: isGamesPage ? "0" : "base.borderRadius.x8",
      },
      bradbr: {
        default: "base.borderRadius.x8",
        large: isGamesPage ? "0" : "base.borderRadius.x8",
      },
      p: "base.spacing.x2",
    }),
    [isGamesPage],
  );

  return (
    <Stack sx={mergedSx} rc={<aside ref={domRef} />} gap="2px" direction="row">
      <Stack sx={farLeftSideMenuSx} gap="base.spacing.x1">
        {menuItems.map((item) => (
          <Fragment key={item.key}>
            {item.visible ? (
              <>
                <MenuItem
                  testId={`menu-item__${item.label}`}
                  size="xSmall"
                  sx={MENU_ITEM_SX}
                  selected={isMenuItemSelected(router.pathname, item.path)}
                  {...(item.path
                    ? {
                        rc: (
                          <Link
                            href={{ pathname: `${item.path}` }}
                            target={
                              item.linkProps?.isExternal ? "_blank" : undefined
                            }
                            referrerPolicy={
                              item.linkProps?.isTrusted
                                ? "origin"
                                : item.linkProps?.isExternal
                                  ? "no-referrer"
                                  : undefined
                            }
                            rel={
                              item.linkProps?.isTrusted
                                ? "external"
                                : item.linkProps?.isExternal
                                  ? "noreferrer"
                                  : undefined
                            }
                          />
                        ),
                        onClick: ["daily_gems", "quest_gems"].includes(item.key)
                          ? onGemsTab
                          : onItem,
                      }
                    : {
                        onClick: item.onClick,
                      })}
                >
                  <MenuItem.Label>{t(item.label)}</MenuItem.Label>
                  <MenuItem.Icon
                    testId={`menu-item__${item.icon}`}
                    {...(isDualVariantIcon(item.icon)
                      ? { icon: item.icon, variant: item.iconVariant }
                      : {
                          icon: item.icon,
                        })}
                  />

                  {item.content && <item.content walletGems={walletGems} />}

                  {item.activationBadge ? (
                    <item.activationBadge
                      hideActivationBadges={
                        preferencesData.hide_activation_badges
                      }
                      itemKey={item.key}
                    />
                  ) : null}
                </MenuItem>
                {item.divider && (
                  <Divider size="xSmall" sx={{ my: "base.spacing.x6" }} />
                )}
              </>
            ) : null}
          </Fragment>
        ))}
        <Divider
          size="xSmall"
          sx={{
            d: { default: "flex", medium: "none" },
            my: "base.spacing.x6",
          }}
        />
        {rewardsStatusDevPanelEnabled ? <StatusDevPanel /> : null}
      </Stack>
      {isGamesPage && <FilterMenu />}
    </Stack>
  );
}
