import {
  useRewardsProfileApi,
  useRewardsProgression,
} from "@/hooks/useRewardsProfile/useRewardsProfile";
import { Box, Stack, TextInput } from "@biom3/react";
import { type ChangeEvent, useState } from "react";
import { useSWRConfig } from "swr";

const XP_INCREMENT = 20;

/**
 * Only intended for use on dev, or limited to specific accounts on sandbox.
 * Allows for easy manipulation of XP and status.
 */
export function StatusDevPanel() {
  const { rewardsProfile, rewardsProfileMutate } = useRewardsProfileApi();
  const { rewardsProgression } = useRewardsProgression();
  const { mutate: globalMutate } = useSWRConfig();
  const [xpAmount, setXpAmount] = useState(XP_INCREMENT);

  const handleProgressClick = () => {
    if (!rewardsProfile || !rewardsProgression) return;
    const numericXp = xpAmount || XP_INCREMENT;

    const newXp = rewardsProfile.xp + numericXp;

    // Find the highest level where XP meets the requirement
    const newLevel = rewardsProgression.levels
      .filter((level) => newXp >= level.requirements.xp)
      .reduce((max, level) => Math.max(max, level.id), 1);

    // Find the highest status where XP meets the requirement
    const newStatus = rewardsProgression.statuses
      .filter((status) => newXp >= status.requirements.xp)
      .reduce(
        (highest, status) => status.label,
        rewardsProgression.statuses[0].label,
      );

    const updatedProfile = {
      ...rewardsProfile,
      xp: newXp,
      level: newLevel,
      status: newStatus,
    };

    // First update the global cache
    globalMutate("/v1/rewards/profile", updatedProfile, {
      populateCache: true,
      revalidate: false,
    });

    // Then trigger the hook update
    rewardsProfileMutate(updatedProfile, {
      populateCache: true,
      revalidate: false,
    });
  };

  const handleXpChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Only allow numeric input
    if (/^\d*$/.test(value)) {
      setXpAmount(Number(value));
    }
  };

  if (!rewardsProfile) return null;

  return (
    <Box
      sx={{ p: "base.spacing.x4", mt: "auto", bg: "base.color.neutral.800" }}
    >
      <Stack gap="base.spacing.x4">
        <TextInput
          sizeVariant="medium"
          value={xpAmount}
          onChange={handleXpChange}
          hideClearValueButton
          type="number"
          placeholder="Enter XP amount"
          sx={{ minWidth: "160px" }}
        >
          <TextInput.Button onClick={handleProgressClick}>+XP</TextInput.Button>
        </TextInput>

        <Box>
          <pre>{JSON.stringify(rewardsProfile, null, 2)}</pre>
        </Box>
      </Stack>
    </Box>
  );
}
