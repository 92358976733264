import { HOME_PAGE, appConfig } from "@/constants";
import { useFeatureFlag, usePassportProvider } from "@/context";
import { NotificationProvider } from "@/context/NotificationProvider";
import { useFlag } from "@/hooks/useFlags";
import { usePassportLogin } from "@/hooks/usePassportLogin";
import {
  inFacebookBrowser,
  redirectGameToExternalBrowser,
} from "@/utils/loginredirect";
import { trackLegacyEvent } from "@analytics";
import { AppHeaderBar, Box, Button, CloudImage, Stack } from "@biom3/react";
import { useIsLessThanMediumScreen } from "@ui-kit/hooks/screenSizeHooks";
import Link from "next/link";
import { type Ref, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileDrawer } from "../ProfileDrawer/ProfileDrawer";
import { RewardChestNav } from "../RewardChestNav/RewardChestNav";
import { LoadingModal } from "./LoadingModal";

export function Header({
  domRef,
  onMenuClick,
  onTitleClick,
}: {
  domRef: Ref<HTMLDivElement> | undefined;
  onMenuClick: () => void;
  onTitleClick: () => void;
}) {
  const flags = useFeatureFlag();
  const { t } = useTranslation();
  const { login, loading } = usePassportLogin();
  const { walletAddress, isLoggedIn, passportState } = usePassportProvider();
  const [isCopyWalletModalVisible, setCopyWalletModalVisible] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const takeoverBackgroundEnabled = useFlag("engagementFeaturedQuestThemedBg");
  const isSmallScreenMode = useIsLessThanMediumScreen();

  const onLogin = () => {
    const inAdBrowser = inFacebookBrowser();
    const redirectEnabled =
      flags["facebook-ad-redirect-enabled"] === "VariantA";
    const extras = inAdBrowser ? { redirectEnabled } : {};
    trackLegacyEvent({
      userJourney: "Activity",
      screen: "Header",
      control: "SignIn",
      controlType: "Button",
      action: "Pressed",
      extras: {
        inAdBrowser: inFacebookBrowser(),
        path: window.location.pathname,
        ...extras,
      },
    });

    // If we are on a games page in an ad browser, we need to redirect to the external browser
    if (!redirectEnabled || !redirectGameToExternalBrowser(false)) {
      login();
    }
  };

  return (
    <>
      <AppHeaderBar
        variant="bright"
        domRef={domRef}
        testId="header"
        contentAlign="left"
        sx={{ pos: "absolute", top: "0", left: "0" }}
      >
        {takeoverBackgroundEnabled ? (
          <Box sx={{ position: "absolute", zIndex: 0 }}>
            <CloudImage
              imageUrl={`${appConfig.ASSET_BASE_URL}/images/quests/ravenquest-takeover-logo.svg`}
              relativeImageSizeInLayout="260px"
              sx={{
                pl: isSmallScreenMode ? "base.spacing.x34" : "base.spacing.x45",
              }}
            />
          </Box>
        ) : null}
        <AppHeaderBar.LeftLogo
          testId="header-logo"
          logo={"PlayHorizontalLockup"}
          rc={<Link onClick={onTitleClick} href={HOME_PAGE} />}
          sx={{
            cursor: "pointer",
            d: { default: "none", medium: "flex" },
          }}
        />
        <AppHeaderBar.LeftLogo
          testId="compact-header-logo"
          logo={"PlayHorizontalLockupCompact"}
          rc={<Link onClick={onTitleClick} href={HOME_PAGE} />}
          sx={{
            cursor: "pointer",
            d: { default: "flex", medium: "none" },
          }}
        />

        <AppHeaderBar.LeftButtCon
          testId="header-smallscreen"
          size="medium"
          icon="Menu"
          onClick={onMenuClick}
          sx={{ d: { default: "flex", medium: "none" } }}
        />

        <AppHeaderBar.RightSlot>
          <Stack direction="row" gap="base.spacing.x8" alignItems="center">
            {isLoggedIn && <RewardChestNav />}
            {!isLoggedIn && !walletAddress && passportState.ready && (
              <Button
                onClick={onLogin}
                testId="header-signin-button"
                size="medium"
                variant="primary"
              >
                {t("sign_in")}
              </Button>
            )}
            {isLoggedIn && (
              <NotificationProvider>
                <ProfileDrawer
                  setLoggingOut={setLoggingOut}
                  setCopyWalletModalVisible={setCopyWalletModalVisible}
                  isCopyWalletModalVisible={isCopyWalletModalVisible}
                />
              </NotificationProvider>
            )}
          </Stack>
        </AppHeaderBar.RightSlot>
      </AppHeaderBar>
      <LoadingModal loading={loading} text={t("logging_in")} />
      <LoadingModal loading={loggingOut} text={t("logging_out")} />
    </>
  );
}
