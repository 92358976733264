import { SIDEBAR_WIDTH } from "@/constants";
import { type Filters, useFilter } from "@/context/FilterProvider";
import { trackLegacyEvent } from "@analytics";
import { type DeeplyNestedSx, InputList, Stack, TextInput } from "@biom3/react";
import { useCallback, useState } from "react";
import { merge } from "ts-deepmerge";

export const createFilterState = (filters: Filters) => ({
  platform: new Set(filters.platform || []),
  genre: new Set(filters.genre || []),
});

export const handleFilterChange = (
  selectedFilters: Record<string, Set<string>>,
  filterKey: string,
  value: string,
) => {
  const newSelected = new Set(selectedFilters[filterKey]);
  newSelected.has(value) ? newSelected.delete(value) : newSelected.add(value);
  return newSelected;
};

export const createFiltersFromSelected = (
  selectedFilters: Record<string, Set<string>>,
  search: string[] = [],
): Filters => ({
  platform: Array.from(selectedFilters.platform || []),
  genre: Array.from(selectedFilters.genre || []),
  search: search || [],
});

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number,
) => {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const CONTAINER_SX = {
  d: { default: "none", large: "flex" },
  p: "base.spacing.x4",
  h: "100%",
  w: SIDEBAR_WIDTH,
  bradtr: "base.borderRadius.x8",
  bradbr: "base.borderRadius.x8",
  background: "base.color.translucent.standard.150",
  overflowY: "auto",
};

const TEXT_INPUT_SX = { minWidth: "50%", mb: "base.spacing.x4" };
const INPUT_LIST_SX = { mb: "base.spacing.x4" };

export function FilterMenu({ sx = {} }: { sx?: DeeplyNestedSx }) {
  const { filters, setFilters, filterConfig } = useFilter();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, Set<string>>
  >(createFilterState(filters));

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setFilters({
        platform: Array.from(selectedFilters.platform || []),
        genre: Array.from(selectedFilters.genre || []),
        search: term ? [term.toLowerCase()] : [],
      });

      if (term) {
        trackLegacyEvent({
          screen: "GamesList",
          userJourney: "Games",
          action: "Changed",
          control: "Filters",
          controlType: "TextInput",
          extras: {
            platform: Array.from(selectedFilters.platform || []),
            genre: Array.from(selectedFilters.genre || []),
            search: term ? term.toLowerCase() : null,
            filterType: "desktop",
          },
        });
      }
    }, 500),
    [selectedFilters],
  );

  const onFilterChange = (filterKey: string, value: string) => {
    const newSelected = handleFilterChange(selectedFilters, filterKey, value);

    setSelectedFilters((prev) => ({
      ...prev,
      [filterKey]: newSelected,
    }));

    setFilters({
      platform: Array.from(
        filterKey === "platform" ? newSelected : selectedFilters.platform,
      ),
      genre: Array.from(
        filterKey === "genre" ? newSelected : selectedFilters.genre,
      ),
      search: searchTerm ? [searchTerm.toLowerCase()] : [],
    });

    trackLegacyEvent({
      screen: "GamesList",
      userJourney: "Games",
      action: "Changed",
      control: "Filters",
      controlType: "Checkbox",
      extras: {
        platform: Array.from(selectedFilters.platform || []),
        genre: Array.from(selectedFilters.genre || []),
        search: searchTerm ? searchTerm.toLowerCase() : null,
        filterType: "desktop",
      },
    });
  };

  const handleCheckBoxChange = (filterKey: string, optionValue: string) => () =>
    onFilterChange(filterKey, optionValue);

  return (
    <Stack sx={merge(CONTAINER_SX, sx)}>
      <TextInput
        placeholder="Title"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          debouncedSearch(e.target.value);
        }}
        sizeVariant="medium"
        sx={TEXT_INPUT_SX}
        hideClearValueButton={true}
      >
        <TextInput.Icon icon="Search" />
      </TextInput>
      {Object.entries(filterConfig).map(([key, config]) => (
        <InputList key={key} hideFormControlDividers sx={INPUT_LIST_SX}>
          <InputList.Label weight="bold" size="xSmall">
            {config?.title?.toUpperCase()}
          </InputList.Label>
          {config?.options?.map((option) => (
            <InputList.FormControl key={option.value} textAlign="right">
              <InputList.FormControl.Label>
                {option.label}
              </InputList.FormControl.Label>
              <InputList.inputs.Checkbox
                checked={selectedFilters[key]?.has(option.value)}
                onChange={handleCheckBoxChange(key, option.value)}
              />
            </InputList.FormControl>
          ))}
        </InputList>
      ))}
    </Stack>
  );
}
