import { Box, type DeeplyNestedSx } from "@biom3/react";
import type { ReactNode } from "react";
import { merge } from "ts-deepmerge";

export const MainContent = ({
  children,
  contentSx = {},
}: {
  children: ReactNode;
  contentSx?: DeeplyNestedSx;
}) => {
  return (
    <Box
      testId="MainContent"
      rc={<main />}
      sx={merge(
        {
          d: "flex",
          flexGrow: 1,
          flexDirection: "column",
          w: "100%",
        },
        contentSx,
      )}
    >
      <Box
        testId="MainContent__inner"
        sx={{
          pos: "relative",
          flex: "1",
          background: "base.color.translucent.standard.150",
          borderRadius: "base.borderRadius.x8",
          px: "base.spacing.x6",
          pt: "base.spacing.x4",
          pb: "base.spacing.x10",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
