import { trackEvent } from "@analytics";
import {
  Badge,
  Body,
  Box,
  ButtCon,
  Button,
  Heading,
  Stack,
  Video,
} from "@biom3/react";
import { useRouter } from "next/router";
import { type ReactNode, useCallback, useEffect } from "react";

interface PromoBannerAnalytics {
  section: string;
  gameId: string;
  gameName: string;
}

interface PromoBannerProps {
  onClose: () => void;
  gameLink: string;
  badgeContent: string;
  heading: ReactNode;
  description: string;
  buttonText: string;
  videoUrl: string;
  analytics: PromoBannerAnalytics;
}

export function PromoBanner({
  onClose,
  analytics,
  gameLink,
  badgeContent,
  heading,
  description,
  buttonText,
  videoUrl,
}: PromoBannerProps) {
  const router = useRouter();

  const { section, gameId, gameName } = analytics;

  const handleLearnMore = useCallback(() => {
    trackEvent({
      component: "Button",
      action: "Pressed",
      extras: {
        control: "LearnMore",
        section,
        gameId,
        gameName,
      },
    });
    router.push(gameLink);
    onClose();
  }, [onClose, router, gameLink, section, gameId, gameName]);

  const handleClose = useCallback(() => {
    trackEvent({
      component: "Button",
      action: "Pressed",
      extras: {
        control: "Close",
        section,
        gameId,
        gameName,
      },
    });
    onClose();
  }, [onClose, section, gameId, gameName]);

  useEffect(() => {
    trackEvent({
      component: "Banner",
      action: "Viewed",
      extras: {
        control: "View",
        section,
        gameId,
        gameName,
      },
    });
  }, [section, gameId, gameName]);

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: "base.spacing.x2",
        mb: "base.spacing.x4",
      }}
    >
      <Video
        sx={{
          w: "100%",
          h: "100%",
          position: "absolute",
          borderRadius: "base.spacing.x2",
          left: "0px",
          objectFit: "cover",
          filter: "brightness(0.5)",
        }}
        mimeType="video/webm"
        videoUrl={videoUrl}
        autoPlay
        preload="auto"
        loop
        muted
        controls={false}
      />
      <Box
        sx={{
          width: "100%",
          p: "base.spacing.x8",
          zIndex: 1,
          position: "relative",
          paddingX: "base.spacing.x12",
        }}
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Stack
            sx={{
              minWidth: ["auto", "300px", "350px"],
              height: "100%",
              flex: {
                default: "100%",
                medium: "1",
              },
              alignItems: "flex-start",
            }}
          >
            {badgeContent && (
              <Badge
                badgeContent={badgeContent}
                variant="emphasis"
                sx={{ mb: "base.spacing.x1" }}
              />
            )}
            <Heading
              size="medium"
              weight="bold"
              color="primary"
              sx={{
                mb: "base.spacing.x1",
                textAlign: "left",
              }}
            >
              {heading}
            </Heading>
            {description && (
              <Body
                size="small"
                weight="regular"
                color="primary"
                sx={{ maxWidth: "345px", textAlign: "left" }}
              >
                {description}
              </Body>
            )}
          </Stack>
          <Button
            variant="primary"
            size={["medium", "medium", "medium", "medium", "large"]}
            sx={{
              mt: "base.spacing.x3",
            }}
            onClick={handleLearnMore}
          >
            {buttonText}
          </Button>
        </Stack>
        <ButtCon
          icon="Close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "base.spacing.x6",
            right: "base.spacing.x6",
          }}
          variant="tertiary"
          size="medium"
        />
      </Box>
    </Box>
  );
}
